<template>
  <Content :item="item" :parent="parent" class="text-xl" />
</template>

<script>
export default {
  name: 'Intro',

  components: {
    Content: () => import('@/components/Items/Content'),
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },

    parent: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
